import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../screen-config';
import { DefaultHeaderStyle } from '../../../header-style';
import styled from 'styled-components/native';
import React, { useLayoutEffect } from 'react';
import { Text } from 'react-native';
import { ApolloClient } from '@apollo/client';
import { WaffleOrange } from '../../Constants/Style';
import { Icon } from 'react-native-elements';
import { goHome } from '../../Helper/NavigationService';
import Sentry from '../../Sentry';

const HeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const MyImage = styled.Image`
  width: 152px;
  height: 43px;
`;

const ItemContainer = styled.View`
  flex-direction: inherit;
  align-items: inherit;
  margin-right: auto;
`;

/**
 * Assuming we aren't on really small resolution devices, absolute is probably fine.
 * If adding more items then perhaps remove absolute and let the flexbox align it correctly during collision
 */
const GoHomeButton = styled.TouchableOpacity`
  position: absolute;
`;

const tryGoHome = async (
  navigation: StackNavigationProp<RootStackParamList>,
  client: ApolloClient<object>,
  isGuest: boolean
): Promise<void> => {
  try {
    await goHome(client, navigation, isGuest);
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
};

const LadderNavigation = (
  navigation: StackNavigationProp<RootStackParamList>,
  client: ApolloClient<object>,
  isGuest: boolean
) => (
  <>
    <HeaderContainer>
      <ItemContainer>
        <GoHomeButton
          onPress={() => tryGoHome(navigation, client, isGuest)}
          testID="touchable-home-btn">
          <Icon
            tvParallaxProperties={{}}
            name={'home'}
            type={'material-community'}
            color={WaffleOrange}
            size={30}
          />
        </GoHomeButton>
      </ItemContainer>
      <ItemContainer>
        <Text style={{ fontSize: 15, fontFamily: 'Circular Std' }}>
          Powered by
        </Text>
        <MyImage source={require('./assets/ladder-logo.png')} />
      </ItemContainer>
    </HeaderContainer>
  </>
);

export function useLadderLogoNavigationHeader(
  navigation: StackNavigationProp<RootStackParamList>,
  title: string,
  client: ApolloClient<object>,
  isGuest: boolean
): void {
  const LadderNavWrapper = () => {
    return LadderNavigation(navigation, client, isGuest);
  };
  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      headerTitle: LadderNavWrapper,
      headerRight: null,
      headerLeft: null,
      headerStyle: {
        ...DefaultHeaderStyle,
        backgroundColor: '#E5E5E5',
      },
      gestureEnabled: false,
    });
  }, [navigation]);
}
