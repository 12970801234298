import { useApolloClient } from '@apollo/client';

import React, { useEffect, useLayoutEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Divider } from 'react-native-elements';

import { HorizontalPacked } from '../Components/SimpleLayouts';
import {
  WaffleDarkBlue,
  WaffleFadedBlack,
  WaffleGreen,
  WaffleLightBlue,
  WaffleOrange,
  WaffleYellow,
  White,
  WindowWidth,
} from '../Constants/Style';
import { OrangeHeaderImage } from '../Components/HeaderNavTitle.web';
import { Logger } from '../Helper/Logger';
import { RefreshUserState } from '../Helper/UserHelpers';
import { CurrentPlan } from '../Components/Home/CurrentPlan';
import { PlainView, Spacer } from '../Components/SimpleComponents';
import { HelloHeader, HomeButton } from '../Components/Home/HomeShared';
import {
  ContentFragment,
  ContentPackedScroll,
  ContentUnified,
} from '../Components/ContentShared';
import { StackScreenProps } from '@react-navigation/stack';
import {
  ClaimChatScreenName,
  DailyLifeSaverScreenName,
  GiftScreenName,
  ReferralScreenName,
  RootStackParamList,
} from '../../screen-config';
import {
  isFullWeb,
  isMobileWeb,
  isNativeOrMobileWeb,
  IsSmallScreen,
} from '../Helper/DeviceHelper';
import { Platform } from 'react-native';
import { FooterHeight } from '../Components/Home/Footer';

const isSmall = IsSmallScreen();
const width = Platform.select({
  ios: isSmall ? 300 : Math.max(WindowWidth() - 50, 335),
  default: 335,
});

const MainButton = ({
  text,
  subtitle = undefined,
  onPress,
  color = White,
  subTitleColor = WaffleFadedBlack,
}) => (
  <HomeButton
    text={text}
    subtitle={subtitle}
    color={color}
    width={isFullWeb() ? 355 : width}
    height={isFullWeb() ? 100 : 69}
    containerStyle={isNativeOrMobileWeb() && { marginTop: 8, marginBottom: 8 }}
    subTitleColor={subTitleColor}
    chevronColor={isFullWeb() ? White : WaffleDarkBlue}
    onPress={onPress}
  />
);

const DailyLifeSaver = () => {
  const navigation = useNavigation();
  return (
    <MainButton
      text={'Daily Life Saver'}
      color={WaffleGreen}
      subTitleColor={White}
      onPress={() => navigation.navigate(DailyLifeSaverScreenName)}
    />
  );
};

const ReferYourFriends = () => {
  const navigation = useNavigation();
  return (
    <MainButton
      text={isFullWeb() ? 'Refer a friend' : 'Refer Someone'}
      color={isFullWeb() ? WaffleYellow : WaffleLightBlue}
      onPress={() => navigation.navigate(ReferralScreenName)}
    />
  );
};

const SomethingBadHappened = () => {
  const navigation = useNavigation();
  return (
    <MainButton
      text={'Something Bad Happened'}
      color={WaffleOrange}
      onPress={() => navigation.navigate(ClaimChatScreenName)}
    />
  );
};

const ProtectSomeoneYouLove = () => {
  const navigation = useNavigation();

  return (
    <MainButton
      text={
        isFullWeb()
          ? 'Protect your friends and family'
          : 'Protect Your Loved Ones'
      }
      color={isFullWeb() ? WaffleLightBlue : WaffleYellow}
      onPress={() => navigation.navigate(GiftScreenName)}
    />
  );
};

const DividerOrSpacer = ({ style }) => {
  if (isFullWeb()) {
    return <Spacer x={1} y={2} />;
  } else {
    return <Divider style={style} />;
  }
};

const HomeScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Home'>) => {
  const client = useApolloClient();
  const style = { width: isFullWeb() ? 720 : width };
  const Wrapper = isFullWeb() ? ContentFragment : ContentPackedScroll;
  const ButtonRow = isFullWeb() ? HorizontalPacked : React.Fragment;
  const WrapperViewMaybe = ({ children }) =>
    isFullWeb() ? (
      <PlainView style={style}>{children}</PlainView>
    ) : (
      <>{children}</>
    );

  useEffect(() => {
    (async () => {
      Logger('HomeScreen: refresh user state');
      await RefreshUserState(client);
    })();
  }, [client]);

  useLayoutEffect(() => {
    if (!navigation) {
      Logger(`navigation is NULL!!!`);
    }
    navigation.setOptions({
      header: () => (isFullWeb() ? <></> : <HelloHeader />),
      title: '',
    });
  }, [navigation]);

  return (
    <ContentUnified image={OrangeHeaderImage} ChildrenWrapper={Wrapper}>
      <WrapperViewMaybe>
        {isNativeOrMobileWeb() && <Spacer y={2} />}

        <CurrentPlan />

        <Spacer y={isNativeOrMobileWeb() ? 2.5 : 2} />

        <Divider style={style} />

        {Platform.OS !== 'web' && <DailyLifeSaver />}

        <DividerOrSpacer style={style} />

        <ButtonRow>
          <ProtectSomeoneYouLove />

          <DividerOrSpacer style={style} />

          <SomethingBadHappened />
        </ButtonRow>

        <DividerOrSpacer style={style} />

        <ButtonRow>
          <ReferYourFriends />
        </ButtonRow>

        {isMobileWeb() && <Spacer y={FooterHeight() / 8.0} />}
      </WrapperViewMaybe>
    </ContentUnified>
  );
};

export default HomeScreen;
