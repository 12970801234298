import { StackScreenProps } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import {
  LifeApplicationProcessingScreenName,
  LifeApplicationReviewScreenName,
  LifeApplicationDeclined,
  RootStackParamList,
  LifeRedirectScreenName,
} from '../../../screen-config';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import WaffleText from '../../Components/WaffleText';
import { Spacer } from '../../Components/SimpleComponents';
import {
  answerQuestion,
  getApplication,
  Question,
} from '../LifeQuoteScreen/models/application';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { useLadderLogoNavigationHeader } from './useLadderNavigationHeader';
import LifeDisclosure from '../../Components/LifeDisclosure';
import { useApolloClient } from '@apollo/client';
import {
  getLifePolicyMetaDataAsync,
  useLifeInsurance,
} from '../../../lib/life';
import LifeReviewQuestions from './LifeReviewQuestions';
import LoadingRectangle from '../../Components/LoadingRectangle';
import Sentry from '../../Sentry';
import { useAuthContext } from '../AuthProvider';
import LifeContainer from '../../Helper/LifeContainer';

const Card = styled.div`
  background: white;
  border-radius: 20px;
  box-shadow: 0px 4px 28px -9px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 375px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;

const FinePrintContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Border = styled.div`
  align-self: center;
  opacity: 0.1;
  width: 90%;
  border: 1px solid #000000;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const title = 'You made it! Now, let’s review.';
const subTitle =
  "We use this info to create your personalized offer, so make sure everything's right. You can edit any answer by clicking it.";

type ReviewScreenState = {
  questions: Question[];
  isLoading: boolean;
  isProcessing: boolean;
  appHash: string;
  appId: string;
  finePrint: string;
  finePrintTitle: string;
  error: string;
};

function getFinePrintObject(questions: Question[] = []): {
  finePrint: string;
  caption: string;
} {
  for (let i = questions.length - 1; i > 0; i--) {
    const q = questions[i];
    if (q.id === 'ApplicationApiFraudLanguage') {
      return {
        finePrint: q.subcaption,
        caption: q.caption,
      };
    }
  }

  return {
    finePrint: '',
    caption: '',
  };
}
export default function LifeApplicationReviewScreen({
  navigation,
}: StackScreenProps<
  RootStackParamList,
  'Life Application Review'
>): React.ReactElement {
  const [screenState, setScreenState] = useState<ReviewScreenState>({
    appHash: null,
    appId: null,
    questions: [],
    finePrint: '',
    finePrintTitle: "Don't forget the fine print!",
    isLoading: true,
    isProcessing: false,
    error: null,
  });
  const { isGuest } = useAuthContext();
  const apolloClient = useApolloClient();
  const { setRedirectReason } = useLifeInsurance();

  useLadderLogoNavigationHeader(
    navigation,
    LifeApplicationReviewScreenName,
    apolloClient,
    isGuest
  );

  async function onSubmit() {
    try {
      const response = await answerQuestion(screenState.appId, {
        id: 'ApplicationApiFraudLanguage',
        value: 'Yes',
      });

      if (response.eligible_for_api === false) {
        setRedirectReason(response.ineligibility_reason);
        navigation.navigate(LifeRedirectScreenName);
      } else if (response.ineligible) {
        navigation.navigate(LifeApplicationDeclined);
      } else {
        navigation.navigate(LifeApplicationProcessingScreenName);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  useFocusEffect(
    useCallback(() => {
      (async () => {
        const draftLifePolicyMetaData = await getLifePolicyMetaDataAsync(
          apolloClient
        );

        const application = await getApplication(
          draftLifePolicyMetaData?.ExternalApplicationId
        );

        if (application.signed_at) {
          navigation.navigate(LifeApplicationProcessingScreenName);
          return;
        }

        let questions = application.answered_questions;

        if (
          application.next_incomplete_question &&
          application.next_incomplete_question.id ===
            'ApplicationApiFraudLanguage'
        ) {
          questions = questions.concat(application.next_incomplete_question);
        }

        const finePrintObject = getFinePrintObject(questions);

        setScreenState({
          ...screenState,
          isLoading: false,
          questions,
          appHash: application.hash,
          appId: application.id,
          finePrintTitle: finePrintObject.caption,
          finePrint: finePrintObject.finePrint,
        });
      })();
    }, [])
  );

  return (
    <LifeContainer>
      <Spacer y={4} />
      <Card>
        <Spacer y={4} />
        <WaffleText style={{ fontSize: 36, textAlign: 'center' }}>
          {title}
        </WaffleText>
        <Spacer y={2} />
        <WaffleText
          style={{ fontSize: 15, fontWeight: '400', textAlign: 'center' }}>
          {subTitle}
        </WaffleText>
        <Spacer y={6} />
        <LifeReviewQuestions
          questions={screenState.questions}
          isLoading={screenState.isLoading}
        />
        <Border />
        <FinePrintContainer>
          <Spacer y={4} />
          {screenState.isLoading ? (
            <LoadingRectangle width={340} height={33} />
          ) : (
            <WaffleText
              style={{ fontSize: 26, fontWeight: '600', textAlign: 'center' }}>
              {screenState.finePrintTitle}
            </WaffleText>
          )}
          <Spacer y={4} />
          <WaffleText
            style={{ fontSize: 15, fontWeight: '400', textAlign: 'center' }}>
            Next, you’ll see your offer and can decide whether to accept it.
          </WaffleText>
          <Spacer y={4} />
          {screenState.isLoading ? (
            <>
              <LoadingRectangle width="100%" height={8} />
              <LoadingRectangle width="100%" height={8} />
              <LoadingRectangle width="100%" height={8} />
              <LoadingRectangle width="100%" height={8} />
              <LoadingRectangle width="100%" height={8} />
            </>
          ) : (
            <WaffleText
              style={{ fontSize: 12, fontWeight: '400', textAlign: 'justify' }}>
              {screenState.finePrint}
            </WaffleText>
          )}
          <Spacer y={4} />
          <Border />
        </FinePrintContainer>
        <Spacer y={4} />
        <FooterContainer>
          <WaffleButtonAsync
            disabled={screenState.isLoading}
            onPress={onSubmit}
            name="Submit"
          />
          <Spacer y={4} />
        </FooterContainer>
      </Card>
      <Spacer y={4} />
      <LifeDisclosure />
    </LifeContainer>
  );
}
