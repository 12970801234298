import React from 'react';
import styled from 'styled-components/native';
import { Spacer } from '../Components/SimpleComponents';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import {
  LifeApplicationDeclined,
  ProductChooserScreenName,
  RootStackParamList,
} from '../../screen-config';
import WaffleText from '../Components/WaffleText';
import { StackScreenProps } from '@react-navigation/stack';
import { useLadderLogoNavigationHeader } from './LifeApplicationReviewScreen/useLadderNavigationHeader';
import LifeDisclosure from '../Components/LifeDisclosure';
import { useApolloClient } from '@apollo/client';
import Sentry from '../Sentry';
import { USER_STATE_PRODUCT_CHOOSER } from '../Helper/NavigationService';
import { useAuthContext } from './AuthProvider';
import { UpdateUserStateOnServer } from '../Helper/UserHelpers';

const Container = styled.View`
  display: flex;
  background: #fff8f3;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  height: 100%;
`;

const SorryImage = styled.Image`
  width: 100%;
  height: 240px;
  top: -40px;
`;
const ImageBackground = styled.View`
  background-color: #f98d46;
  border-radius: 20px;
  width: 372px;
  height: 200px;
`;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const sorryImageSrc = require('../../assets/images/sad-man.png');

export default function LifeApplicationDeclinedScreen({
  navigation,
}: StackScreenProps<
  RootStackParamList,
  'Life Application Declined'
>): React.ReactElement {
  const { isGuest } = useAuthContext();
  const client = useApolloClient();
  useLadderLogoNavigationHeader(
    navigation,
    LifeApplicationDeclined,
    client,
    isGuest
  );

  async function onViewProducts() {
    try {
      await UpdateUserStateOnServer(client, USER_STATE_PRODUCT_CHOOSER);
      navigation.navigate(ProductChooserScreenName);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }

  return (
    <Container>
      <WaffleText style={{ fontSize: 48 }}>{"We're Sorry!"}</WaffleText>
      <Spacer y={8} />
      <ImageBackground>
        <SorryImage source={sorryImageSrc} />
      </ImageBackground>
      <Spacer y={4} />
      <WaffleText style={{ fontSize: 24, textAlign: 'center' }}>
        {"Based on your inputs, we couldn't offer you coverage at this time."}
      </WaffleText>
      <Spacer y={4} />
      <WaffleButtonAsync onPress={onViewProducts} name="View Other Products" />
      <Spacer y={4} />
      <LifeDisclosure />
    </Container>
  );
}
