import React from 'react';
import WaffleText from '../../Components/WaffleText';
import { Spacer } from '../../Components/SimpleComponents';
import { View } from 'react-native';
import {
  AnswerOption,
  AnswerValue,
  Question,
} from '../LifeQuoteScreen/models/application';
import { Link } from '@react-navigation/native';
import { LifeApplicationScreenName, RouteConfig } from '../../../screen-config';
import { WaffleOrange } from '../../Constants/Style';
import styled from 'styled-components';
import LoadingRectangle from '../../Components/LoadingRectangle';
import { getHeightAnswerOptions } from '../LifeQuoteScreen/utils/generate-quote-answer-options';
import { fmtISOToDisplayDt } from '../LifeQuoteScreen/utils/date-format-helper';
import {
  formatMoney,
  toTitleCase,
} from '../LifeQuoteScreen/utils/display-hint-formatter-factory';

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const AnswerEditRow = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const heightAnswerOptions = getHeightAnswerOptions();

function extractHumanReadableAnswer(question: Question): string {
  // Special cases where we hardcode the question ID should be double checked with ladder life if it's ok to hardcode
  if (question.id === 'InsuredPerson.Height') {
    /*
     * It is possible for a user to change the inches via the edit button that is outside the default height answer option range.
     * The raw value is then displayed if an answer option could not be matched--edge case?
     */
    return extractHumanReadableAnswerFrom(question.answer, heightAnswerOptions);
  } else if (question.type === 'date') {
    return fmtISOToDisplayDt(question.answer as string);
  } else if (question.type === 'integer' && question.display_hint === 'money') {
    return formatMoney((question.answer as number).toString());
  } else if (question.display_hint === 'email') {
    return question.answer as string;
  }

  return extractHumanReadableAnswerFrom(
    question.answer,
    question.answer_options
  );
}

function getOriginalAnswer(answer: AnswerValue) {
  return Array.isArray(answer)
    ? answer.join('\n')
    : toTitleCase(answer.toString());
}

function extractHumanReadableAnswerFrom(
  answer: AnswerValue,
  answerOptions: AnswerOption[]
) {
  if (!answerOptions || answerOptions.length === 0) {
    return getOriginalAnswer(answer);
  }
  const readableAnswer = answerOptions
    .filter((option) => {
      if (Array.isArray(answer)) {
        return answer.includes(option.value as string);
      }
      return option.value === answer;
    })
    .map((o) => toTitleCase(o.text))
    .join('\n');

  return readableAnswer ? readableAnswer : getOriginalAnswer(answer);
}

type LifeReviewQuestionsProps = {
  questions: Question[];
  isLoading: boolean;
};

const LoadingReviewQuestion = () => (
  <ItemContainer>
    <LoadingRectangle height={38} width="100%" />
    <Spacer y={1} />
    <AnswerEditRow>
      <LoadingRectangle width={98} height={25} />
      <LoadingRectangle width={25} height={18} />
    </AnswerEditRow>
  </ItemContainer>
);

export default function LifeReviewQuestions({
  questions = [],
  isLoading,
}: LifeReviewQuestionsProps): React.ReactElement {
  if (isLoading) {
    return (
      <>
        {Array(4)
          .fill(null)
          .map((v, i) => (
            <React.Fragment key={`fragment_${i}`}>
              <LoadingReviewQuestion key={i} />
              <Spacer y={2} />
            </React.Fragment>
          ))}
      </>
    );
  }
  return (
    <>
      {questions
        .filter((q) => q.id !== 'ApplicationApiFraudLanguage')
        .map((q, page) => (
          <ItemContainer key={q.id}>
            <WaffleText style={{ fontSize: 15, fontWeight: '600' }}>
              {q.caption}
            </WaffleText>
            <Spacer y={1} />
            <AnswerEditRow>
              {!q.answer && q.subquestions ? (
                <View>
                  {q.subquestions.map((subQuestion) => (
                    <React.Fragment key={subQuestion.id}>
                      {!subQuestion.caption ? null : (
                        <>
                          <Spacer y={1} />
                          <WaffleText
                            style={{ fontSize: 15, fontWeight: '600' }}>
                            {subQuestion.caption}
                          </WaffleText>
                          <Spacer y={1} />
                          <WaffleText
                            style={{
                              fontSize: 18,
                              fontWeight: 'normal',
                            }}>
                            {!subQuestion.answer
                              ? `<blank>`
                              : extractHumanReadableAnswer(subQuestion)}
                          </WaffleText>
                          <Spacer y={1} />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </View>
              ) : (
                <WaffleText
                  style={{
                    fontSize: 20,
                    maxWidth: '85%',
                    fontWeight: '400',
                  }}>
                  {extractHumanReadableAnswer(q)}
                </WaffleText>
              )}
              <Link
                to={`/${RouteConfig.screens[LifeApplicationScreenName]}?initialPage=${page}`}>
                <WaffleText
                  style={{
                    color: WaffleOrange,
                    textDecorationLine: 'underline',
                  }}>
                  Edit
                </WaffleText>
              </Link>
            </AnswerEditRow>
            <Spacer y={4} />
          </ItemContainer>
        ))}
    </>
  );
}
