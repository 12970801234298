import WaffleText from '../../Components/WaffleText';
import { Spacer } from '../../Components/SimpleComponents';
import AnimatedDots from '../../Components/AnimatedDots';
import React from 'react';
import styled from 'styled-components/native';
import LifeDisclosure from '../../Components/LifeDisclosure';

const Card = styled.View`
  display: flex;
  flex-direction: column;
  max-width: 375px;
  background: #fff8f3;
  justify-content: flex-start;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 12px;
`;

const LoadingContainer = styled.View`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const ScreenImages = {
  waffle: require('./assets/waffle.png'),
  ladder: require('./assets/ladder.png'),
};

const CustomImage = styled.Image`
  width: 100px;
  height: 100px;
`;

type Props = {
  title: string;
  message: string;
};

export default function LifeProcessingView({
  title,
  message,
}: Props): React.ReactElement {
  return (
    <Card>
      <WaffleText style={{ fontSize: 45, textAlign: 'center' }}>
        {title}
      </WaffleText>
      <Spacer y={10} />
      <LoadingContainer>
        <CustomImage source={ScreenImages.waffle} />
        <Spacer x={2} />
        <AnimatedDots />
        <Spacer x={2} />
        <CustomImage source={ScreenImages.ladder} />
      </LoadingContainer>
      <Spacer y={8} />
      <WaffleText style={{ fontSize: 24, textAlign: 'center' }}>
        {message}
      </WaffleText>
      <Spacer y={8} />
      <LifeDisclosure />
    </Card>
  );
}
