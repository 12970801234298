import styled from 'styled-components';
import React, { useCallback } from 'react';
import { Spacer } from '../../Components/SimpleComponents';
import { StackScreenProps } from '@react-navigation/stack';
import {
  LifeApplicationProcessingScreenName,
  LifeOfferScreenName,
  LifeApplicationDeclined,
  LifeRedirectScreenName,
  RootStackParamList,
} from '../../../screen-config';
import {
  getApplication,
  getApplicationDecision,
  signApplication,
} from '../LifeQuoteScreen/models/application';
import { useLadderLogoNavigationHeader } from './useLadderNavigationHeader';
import {
  getLifePolicyMetaDataAsync,
  useLifeInsurance,
} from '../../../lib/life';
import LifeProcessingView from './LifeProcessingView';
import { useApolloClient } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import Sentry from '../../Sentry';
import { useAuthContext } from '../AuthProvider';
import LifeContainer from '../../Helper/LifeContainer';

export default function LifeProcessingScreen({
  navigation,
}: StackScreenProps<
  RootStackParamList,
  'Life Application Processing'
>): React.ReactElement {
  const { setRedirectReason } = useLifeInsurance();
  const { isGuest } = useAuthContext();
  const apolloClient = useApolloClient();

  useLadderLogoNavigationHeader(
    navigation,
    LifeApplicationProcessingScreenName,
    apolloClient,
    isGuest
  );

  async function _getApplicationDecision(
    applicationId: string,
    poller: number | null
  ) {
    const pollerIsDefined = () => poller !== null || poller !== undefined;
    try {
      const decision = await getApplicationDecision(applicationId);
      if (decision.status === 'completed' && decision.decision === 'term') {
        if (pollerIsDefined()) clearInterval(poller);
        navigation.navigate(LifeOfferScreenName);
      } else if (decision.decision === 'manual_underwriting') {
        if (pollerIsDefined()) clearInterval(poller);
        setRedirectReason('Application requires manual underwriting');
        navigation.navigate(LifeRedirectScreenName);
      } else if (decision.decision === 'declined') {
        if (pollerIsDefined()) clearInterval(poller);
        setRedirectReason('Application was declined');
        navigation.navigate(LifeApplicationDeclined);
      }
    } catch (e) {
      Sentry.captureException(e);
      if (pollerIsDefined()) clearInterval(poller);
    }
  }

  useFocusEffect(
    useCallback(() => {
      let poller = -1;
      (async () => {
        try {
          const lifePolicyMetaData = await getLifePolicyMetaDataAsync(
            apolloClient
          );
          const application = await getApplication(
            lifePolicyMetaData?.ExternalApplicationId
          );
          if (application.ineligible) {
            setRedirectReason(application.ineligibility_reason);
            navigation.navigate(LifeApplicationDeclined);
            return;
          } else if (application.identity_status !== 'verified') {
            setRedirectReason(
              `Identity verification error ${application.identity_status}`
            );
            navigation.navigate(LifeApplicationDeclined);
            return;
          } else if (!application.signed_at) {
            await signApplication(application.id, application.hash);
          }
          await _getApplicationDecision(application.id, poller);

          poller = setInterval(() => {
            _getApplicationDecision(application.id, poller)
              .then()
              .catch(console.error);
          }, 20000);
        } catch (e) {
          clearInterval(poller);
          console.error(e);
          Sentry.captureException(e);
          setRedirectReason(`Unexpected error`);
          navigation.navigate(LifeApplicationDeclined);
          return;
        }
      })();
      return () => {
        clearInterval(poller);
      };
    }, [])
  );

  return (
    <LifeContainer>
      <Spacer y={4} />
      <LifeProcessingView
        title="Processing your Application!"
        message="This will take less than 2 minutes. Thanks for waiting!"
      />
      <Spacer y={4} />
    </LifeContainer>
  );
}
